import React, { useEffect } from "react";
import { useAttendeeStatus,useRosterState} from "amazon-chime-sdk-component-library-react";
import mut from "../assets/svgs/micOff.svg";
import unmut from "../assets/svgs/micOn.svg";
const axios = require("axios");

function SingleAttendee({ id , smallSize}) {
  let status = useAttendeeStatus(id);
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  let attendeeName;
  let _firstName = "",_lastName = "";
  
  attendees.map((attendee) => {
    const { chimeAttendeeId, name } = attendee;
    if(chimeAttendeeId==id) attendeeName = name;
    if(attendeeName) {
      _firstName = attendeeName.split(" ")[0]|| "";
      _lastName = attendeeName.split(" ")[1] || "";
      _firstName = `${_firstName[0]?.toUpperCase()}${_firstName.slice(1)}`;
      _lastName = `${_lastName?.[0]?.toUpperCase() || "" }${_lastName?.slice(1) || "" }` ;
   }
  })
  return (
    <>
     <div className="tileControls">
        <div className={smallSize ? "nameContainer nameContainer--small" : "nameContainer"}><span className = {smallSize ? "nameText nameText--small" : "nameText"}><span>{_firstName}</span>&nbsp;<span>{_lastName}</span></span></div>
      <div style = {{backgroundColor : "#47484D"}}className={smallSize ? "mute-status mute-status--small" : "mute-status"}>
        {status.muted ? (
          <img style = {{scale : "0.8"}}className="mut" src={mut} alt="mut" />
        ) : (
          <img style = {{scale : "0.8"}}className="unmut" src={unmut} alt="unmut" />
        )}
      </div>
      </div>
    </>
  );
}

export default SingleAttendee;
