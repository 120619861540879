import React,{useState , useEffect} from "react";
import "../App.css";
import {
  ContentShare,
  useContentShareState,
  useRosterState
} from "amazon-chime-sdk-component-library-react";


const ScreenShare = ({ attendeeId , attendeeName}) => {
const { roster } = useRosterState();
const attendees = Object.values(roster);
const [screenSharingAttendeeName,setScreenSharingAttendeeName] = useState("");

useEffect(() => {
  let _attendeeName = attendees.filter(attendee => attendee["chimeAttendeeId"] === attendeeId?.split("#")[0])[0]?.["name"];
  setScreenSharingAttendeeName(_attendeeName)
}, [attendeeId])

  
  return (
    <>
      {attendeeId != null ? (
        <div id="contentshare-container">
          <ContentShare />
          <div className="contentshare-container-attendeee_name"><span>{screenSharingAttendeeName}</span></div>
        </div>
      ) : (
        <></>
      )}
    </>
  );
};

export default ScreenShare;
