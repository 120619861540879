import React from "react";
import {
  useRosterState,
  Roster,
  RosterGroup,
  RosterAttendee,
} from "amazon-chime-sdk-component-library-react";

const AttendeeRoster = () => {
  const { roster } = useRosterState();
  const attendees = Object.values(roster);
  const attendeeItems = attendees.map((attendee) => {
    const { chimeAttendeeId, name , role } = attendee;
    return (
      <>
        <div className="roster-attendee">
          <div>
            <div className="profile-pic">{attendee.name?.[0]}</div>
          </div>
          <div className="roster-attendee-name-role-container">
          
          <RosterAttendee
            className="roster-attendee-item"
            key={chimeAttendeeId}
            attendeeId={chimeAttendeeId}
          />
           <div className="roster-attendee-name-role">
            <span>{role || "AV User"}</span>
          </div>
          </div>
        </div>
      </>
    );
  });
  //setAttendeesList(attendeeItems);
  return (
    <Roster>
      <RosterGroup>{attendeeItems}</RosterGroup>
    </Roster>
  );
};
export default AttendeeRoster;
