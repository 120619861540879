import React from "react";
// import Home from "./components/home";
import {
  
  BrowserRouter,
  Route,
  Switch,
} from "react-router-dom";
// import Footer from "./components/Footer";
import JoinMeeting from "./components/JoinMeeting";
import Loader from "../src/components/loader";

// my imports

import "./App.css";

import NoInternetConnection from "./components/NoInternetConnection";


const EmptyView = () =>  <Loader showLoader={true} loaderTitle={"Getting you back to the workspace.."} />;
function App() {
   return(<BrowserRouter>
      <NoInternetConnection>
        <Switch>
          <Route exact path="/">
            <JoinMeeting  />
          </Route>
          <Route path="/closeView">
            <EmptyView />
          </Route>
        
        </Switch>
        </NoInternetConnection>
      </BrowserRouter>
      );
      
 
}

export default App;
