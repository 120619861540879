import React, {
  Component,
  useState,
  useEffect,
  useRef,
  useCallback,
} from "react";
import ReactDOM from "react-dom";
import QRCode from "react-qr-code";
import { v4 as uuidv4 } from "uuid";
import Sockette from "sockette";
import "./videohome.scss";
import logo from "../assets/svgs/logo-blue.svg";
import { toast, ToastContainer } from "react-toastify";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
  isIE,
  browserName,
  osName,
} from "react-device-detect";
import Loader from "./loader";
import { ConsoleLogger } from "amazon-chime-sdk-js";

const axios = require("axios");

const Login = (props) => {
  const [meetingCode, setMeetingCode] = useState("");
  const [email, setEmail] = useState("");
  const [deviceUniqueId, setDeviceUniqueId] = useState("");
  const [memberId, setMemberId] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [showPasscodePage, setShowPasscodePage] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [inputError, setInputError] = useState(false);
  const [isJoinButtonPressed, setIsJoinButtonPressed] = useState(false);
  const prodEnv = "prod";
  const betaEnv = "beta";
  var ws = null;
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const [isLoaderEnabled, setIsLoaderEnabled] = useState(false);

  const queryParams = new URLSearchParams(window.location.search);
  var meeting_id;
  var meeting_title;
  var env;
  var added_on;

  if (
    queryParams.get("meeting_id") &&
    queryParams.get("meeting_title") &&
    queryParams.get("env")
  ) {
    meeting_id = queryParams.get("meeting_id");
    meeting_title = queryParams.get("meeting_title").split("_").join(" ");
    added_on = parseInt(queryParams.get("added_on"));
    env = queryParams.get("env");
  }

  useEffect(() => {
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.get("code")) {
      handleConfirmJoin(
        true,
        "",
        queryParams.get("email"),
        "",
        queryParams.get("code")
      );
    }
  }, []);

  useEffect(() => {
    if (email.length > 4 && meetingCode.length == 6) {
      setIsSubmitButtonEnabled(true);
    } else {
      setIsSubmitButtonEnabled(false);
    }
  }, [email, meetingCode]);

  const notify = (message) => {
    toast.error(message);
  };

  const handleConfirmJoin = async (
    isAltraUser,
    memberId,
    emailId,
    accessToken,
    code
  ) => {
    setDisabled(true);
    props.handleJoinMeeting(emailId, code ? code : meetingCode, isAltraUser);
    setDisabled(false);
    setIsJoinButtonPressed(false);
  };
  const validateForm = () => {
    const emailRegex = RegExp(
      /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    );
    if (meetingCode == "") {
      notify("Please enter the meeting code!");
      setDisabled(false);
      return false;
    }
    if (email == "") {
      notify("Please enter your email ID!");
      setDisabled(false);
      return false;
    }
    if (!emailRegex.test(email)) {
      notify("Please enter a valid email ID!");
      setDisabled(false);
      return false;
    } else return true;
  };
  const handleSubmit = async (e) => {
    setIsLoaderEnabled(true);
    e.preventDefault();
    let formValidated = validateForm();
    if (formValidated) {
      handleConfirmJoin(false, memberId, email, "");
    } else {
      setIsLoaderEnabled(false);
    }
  };

  const enterPressed = (e) => {
    let code = e.keyCode || e.which;
    if (code === 13) {
      handleSubmit(e);
    }
  };
  return (
    <div className="entryContainer">
      <ToastContainer />
          <div>
            <div className="rightContainer">
              <img
                className="entryContainer__logoContainer__logo"
                src={logo}
                alt="logo" /> 
              <h3 className="entryContainer__description">
                Please enter the correct email and passcode we sent to your email
                invitation
              </h3>
              <div className="entryContainer__userInputsContainer">
                <div className="entryContainer__userInputs">
                  <label className="entryContainer__label">email</label>
                  <input
                    type="email"
                    placeholder="Enter Email"
                    className="entryContainer__textInput"
                    value={email}
                    onChange={(e) => setEmail(e.target.value.toLowerCase())}
                    onKeyDown={enterPressed}
                  />
                </div>
                <div className="entryContainer__userInputs">
                  <label className="entryContainer__label">passcode</label>
                  <input
                    type="text"
                    placeholder="Enter Meeting Passcode"
                    className="entryContainer__textInput"
                    value={meetingCode}
                    onChange={(e) => setMeetingCode(e.target.value)}
                    onKeyDown={enterPressed}
                  />
                </div>
              </div>
              <div className="cc3">
                {isSubmitButtonEnabled ? (
                  <button className={"joinButton"} onClick={handleSubmit}>
                    Enter
                  </button>
                ) : (
                  <button className={"joinButtonDisabled"}>Enter</button>
                )}
              </div>
            </div>
          </div>
    </div>
  );
};

export default Login;

const styles = {
  container: {
    // flex: 1,
    // width: "250px",
    // height: "250px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    backgroundColor: "#fff",
    borderRadius: "10px",
    marginLeft: "4px",
  },
  qrCodeContainer: {
    padding: "30px",
  },
};
