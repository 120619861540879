import React, { useState } from "react";
import ReactDOM from 'react-dom';
import Modal from 'react-modal';
import { Oval, Audio, Circles, TailSpin, Rings } from 'react-loader-spinner'

function InstanceLevelFunctionIdLoader({ showLoader, loaderTitle }) {
    console.log("+++InstanceLevelFunctionIdLoader showLoader, loaderTitle", showLoader, loaderTitle)
    return (
        <Modal
            isOpen={showLoader}
            style={customStyles}
            ariaHideApp={false}
            contentLabel="Example Modal">
            <div className="loaderRow" style={{ justifyContent: 'center', alignItems: 'center' }}>
                <Oval
                    height="30"
                    width="30"
                    radius="15"
                    color='#1C2733'
                    ariaLabel='three-dots-loading'
                    wrapperStyle
                    wrapperClass
                />
                <p className="loaderText">{loaderTitle}</p>
            </div>
        </Modal>
    )
}

const customStyles = {
    content: {
      top: '50%',
      left: '50%',
      right: 'auto',
      bottom: 'auto',
      marginRight: '-50%',
      transform: 'translate(-50%, -50%)',
      borderRadius: 10
    },
  };

export default InstanceLevelFunctionIdLoader;