import React, { useState, useEffect } from "react";
import {
  useMeetingManager,
  useLocalVideo,
  useContentShareControls,
  useToggleLocalMute,
  useContentShareState,
  useRosterState,
  PreviewVideo,
} from "amazon-chime-sdk-component-library-react";
import Webcam from "react-webcam";
import cdkExports from "../cdk-outputs.json";

import "./styles.scss";
import { ToastContainer, toast } from "react-toastify";
import Loader from "./loader";

const axios = require("axios");
const API_URL = cdkExports.MediaCaptureDemo.apiURL;
const region = "us-east-1";
const videoConstraints = {
  width: 100,
  height: 100,
  facingMode: "user",
};

const WaitingRoom = (props) => (
  <div className="waitingRoom">
    <Webcam
      audio={props.waitingRoomAudio}
      height={300}
      screenshotFormat="image/jpeg"
      width={500}
      videoConstraints={videoConstraints}
    ></Webcam>
  </div>
);

export default WaitingRoom;
